import _ from 'lodash'

const EXISTED_CLASS = 'existed'

class CartRecommendedProduct extends HTMLElement {
  constructor () {
    super()

    this.variants = this.dataset.variants ? JSON.parse(this.dataset.variants) : []
    this.buttonSubmit = this.querySelectorAll('.js-button-submit')
    this.id = this.dataset.id
    
    if (this.id) {
      this.checkExistedProduct(this.id)
    }
  }

  checkExistedProduct(id) {
    const cartItems = [].slice.call(document.querySelectorAll('cart-item'))
    
    if (!cartItems.length) return
    
    const existed = cartItems.some(item => item.dataset.id === id)
    
    if (existed) {
      !this.classList.contains(EXISTED_CLASS) && this.classList.add(EXISTED_CLASS)
    } else {
      this.classList.contains(EXISTED_CLASS) && this.classList.remove(EXISTED_CLASS)
    }
  }
  
  onOptionChange (event) {
    const option1 = this.querySelector('[data-option="1"] input[type="radio"]:checked')
    const option2 = this.querySelector('[data-option="2"] input[type="radio"]:checked')
    const option3 = this.querySelector('[data-option="3"] input[type="radio"]:checked')

    let matchingVariant = _.find(this.variants, {
      option1: option1?.value || null,
      option2: option2?.value || null,
      option3: option3?.value || null
    })

    const variantId = matchingVariant.id
    this.querySelector('input[name="id"]').value = variantId
    this.updateImageAndPrice(variantId)
    this.updateCurrentOptionName(event, matchingVariant)
    if (this.buttonSubmit && this.buttonSubmit.length) {
      this.updateButtonATC(matchingVariant)
    }
  }

  updateImageAndPrice (variantId) {
    const items = this.querySelectorAll('[data-product-variant]')
    const visibleItems = this.querySelectorAll(`[data-product-variant="${variantId}"]`)
    if (items && visibleItems) {
      items.forEach(item => {
        item.classList.add('hidden')
      })
      visibleItems.forEach(visibleItem => {
        visibleItem.classList.remove('hidden')
      })
    }
  }

  updateButtonATC (variant) {
    const variantItem = this.querySelector(`[data-product-variant="${variant.id}"][data-pre-launch]`)

    this.buttonSubmit.forEach(btn => {
      btn.disabled = !variant.available || variantItem.dataset.preLaunch === 'true'
    })
  }

  updateCurrentOptionName (event, variant) {
    const optionEl = event.closest('[data-option]')
    const optionNameEl = optionEl ? optionEl.querySelector('.js-option-name') : ''
    if (optionNameEl) {
      const optionName = 'option' + optionEl.dataset.option
      optionNameEl.innerHTML = variant[optionName]
    }
  }

  onSubmitForm () {
    const productId = this.querySelector('input[name="id"]').value
    const note = this.querySelector('input[name="preorder"]')?.value
    const sellingPlanId = this.querySelector('input#type_autodeliver') && this.querySelector('input#type_autodeliver').checked ? 
      this.querySelector('select[name="properties[shipping_interval_frequency]"]').value * 1 
      : ''

    let list_properties = {
      '_gtm_list_id': 'cart_recommended',
      '_gtm_list_name': 'Cart Recommended'
    }

    if (note) {
      list_properties['Note'] = note
    }

    fetch('/cart/add.js', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        id: productId,
        quantity: 1,
        selling_plan: sellingPlanId,
        properties: list_properties,
        sections: 'site-header,cart,mini-cart,blog-listing-header',
        sections_url: window.location.pathname
      })
    }).then((response) => {
      document.querySelector('shopify-cart').onCartUpdate(response)
      this.checkExistedProduct(this.id)
    })
  }
}

if (!customElements.get('cart-recommended-product')) customElements.define('cart-recommended-product', CartRecommendedProduct)
