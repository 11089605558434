const decode = str => decodeURIComponent(str).replace(/\+/g, ' ')

class BuyMoreSaveMore extends HTMLElement {
  constructor () {
    super()
    this.cart = this.closest('shopify-cart')
    this.cartItem = this.closest('cart-item')
    this.buttonEls = this.querySelectorAll('.js-button')

    if (this.buttonEls || this.buttonEls.length) {
      this.handleBuyMoreSaveMore()
    }
  }

  handleBuyMoreSaveMore () {
    this.buttonEls.forEach(button => {
      button.addEventListener('click', (event) => {
        event.preventDefault()
        this.cart.updateQuantity(button)
      })
    })
  }
}

if (!customElements.get('buy-more-save-more')) customElements.define('buy-more-save-more', BuyMoreSaveMore)
