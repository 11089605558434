const decode = str => decodeURIComponent(str).replace(/\+/g, ' ')

class SwitchToSubscription extends HTMLElement {
  constructor () {
    super()
    
    this.cart = this.closest('shopify-cart')
    this.cartItem = this.closest('cart-item')
    this.buttonUpgrade = this.querySelector('[data-subcription-trigger]')
    this.updateDeliveryFrequencyEl = this.querySelector('[data-update-delivery-frequency]')
    if (this.buttonUpgrade) {
      this.handleSwitchToSubscription(this.buttonUpgrade)
    }

    if (this.updateDeliveryFrequencyEl) {
      this.handleUpdateDeliveryFrequency(this.updateDeliveryFrequencyEl)
    }
  }

  handleUpdateDeliveryFrequency (el) {
    const { key, quantity } = this.dataset
    const properties = JSON.parse(decode(this.dataset.properties))
    el.addEventListener('change', (e) => {
      const sellingPlanId = e.target.value
      this.cart.onUpdateDeliveryFrequency(key, quantity, sellingPlanId, properties)
    })
  }

  handleSwitchToSubscription (el) {
    const { key, id, quantity, sellingPlanId } = this.dataset
    const properties = JSON.parse(decode(this.dataset.properties))
    if (!key || !id || !quantity || !sellingPlanId) return
    el.addEventListener('click', () => {
      this.cart.onSwitchToSubscription(key, id, quantity, sellingPlanId, properties)
    })
  }
}

if (!customElements.get('switch-to-subscription')) customElements.define('switch-to-subscription', SwitchToSubscription)
